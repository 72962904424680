import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import './styles/App.css';
import { useDownloads, useProjects } from '_hooks'
import { AboutPage, HomePage } from '_scenes'
import { BreadcrumbsComponent, MenuComponent } from '_components'

function App() {
	return (
		<BrowserRouter>
			<MenuComponent />
			<Container maxWidth="lg">
				<BreadcrumbsComponent />
				<Switch>
					<Route exact path="/" component={HomePage} />
					<Route exact path="/downloads" component={useDownloads} />
					<Route exact path="/projects" component={useProjects} />
					<Route exact path="/about" component={AboutPage} />
				</Switch>
			</Container>
		</BrowserRouter>
	);
}

export default App;
