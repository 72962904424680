import React, { Component } from 'react';

class HomePage extends Component {
  constructor(props: any) {
		super(props);
		document.body.classList.add('homePage');
	}

  render() {
    return (
		<React.Fragment>
			<p>Home:</p>
		</React.Fragment>
    );
  }
}

export { HomePage };
