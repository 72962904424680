import React, { Component } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FileComponent } from './FileComponent'
import { DirectoryType } from '_types'

export type DirectoryComponentProps = {
  directory: DirectoryType;
};

export class DirectoryComponent extends Component<DirectoryComponentProps> {
	render() {
		return (
			<Accordion key={this.props.directory.fullName}>
				<AccordionSummary
				  expandIcon={<ExpandMoreIcon />}
				  aria-controls="panel1a-content"
				  id="panel1a-header">
				  <Typography>{this.props.directory.name}</Typography>
				</AccordionSummary>
				<AccordionDetails>
				  <Container fixed>
					{this.props.directory.files.map((file) => (
					  <FileComponent key={file.name} file={file} />
					))}
					{this.props.directory.directories.map((directory) => (
					  <DirectoryComponent key={directory.name} directory={directory} />
					))}
				  </Container>
				</AccordionDetails>
			</Accordion>
		);
	}
};