import React, { Component } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ProjectType } from '_types'

export type Props = {
	project: ProjectType;
};

export class ProjectComponent extends Component<Props> {
	render() {
		return (
			<Accordion key={this.props.project.name}>
				<AccordionSummary
				  expandIcon={<ExpandMoreIcon />}
				  aria-controls="panel1a-content"
				  id="panel1a-header">
				  <Typography>{this.props.project.name}</Typography>
				</AccordionSummary>
				<AccordionDetails>
				  <Container fixed dangerouslySetInnerHTML={{__html: this.props.project.content}}>
					</Container>
				</AccordionDetails>
			</Accordion>
		);
	}
};