import React, { Component } from 'react';
import Link, { LinkProps } from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Route } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

const breadcrumbNameMap: { [key: string]: string } = {
	'/inbox': 'Inbox',
	'/downloads': 'Downloads',
	'/projects': 'Projects'
};

interface LinkRouterProps extends LinkProps {
	to: string;
	replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

export class BreadcrumbsComponent extends Component {
	render() {
		return (
			<Route>
				{({ location }) => {
					const pathnames = location.pathname.split('/').filter((x) => x);

					return (
					<Breadcrumbs aria-label="breadcrumb">
						<LinkRouter color="inherit" to="/">
							Home
						</LinkRouter>
						{pathnames.map((value, index) => {
						const last = index === pathnames.length - 1;
						const to = `/${pathnames.slice(0, index + 1).join('/')}`;

						return last ? (
							<Typography color="textPrimary" key={to}>
								{breadcrumbNameMap[to]}
							</Typography>
						) : (
						<LinkRouter color="inherit" to={to} key={to}>
							{breadcrumbNameMap[to]}
						</LinkRouter>
						);
						})}
					</Breadcrumbs>
					);
				}}
			</Route>
		);
	}
};