import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import HomeIcon from '@material-ui/icons/Home';
import GetAppIcon from '@material-ui/icons/GetApp';
import WorkIcon from '@material-ui/icons/Work';

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
		textAlign: 'center',
		height: '32px',
		lineHeight: '32px',
		boxShadow: 'none',
		borderRadius: 0,
		backgroundColor: 'rgba(255,255,255,0.5)'
	},
	link: {
		textDecoration: 'none',
		"&:hover, &:focus": {
			color: 'yellow',
			textDecoration: 'none'
		},
	},
	linkWithIcon: {
		position: 'relative',
		padding: '0 24px',
		textDecoration: 'none',
		"&:hover, &:focus": {
			color: 'yellow',
			textDecoration: 'none'
		},
	},
	icon: {
		position: 'absolute',
		top: 0,
		left: 0
	},
});

function MenuComponent() {
	const classes = useStyles();

	return (
		<Paper className={classes.root}>
			<Link href="/" className={classes.linkWithIcon}>
				<HomeIcon className={classes.icon} /> Home
			</Link>
			<Link href="/projects" className={classes.linkWithIcon}>
				<WorkIcon className={classes.icon} /> Projects
			</Link>
			<Link href="/downloads" className={classes.linkWithIcon}>
				<GetAppIcon className={classes.icon} /> Downloads
			</Link>
			<Link href="/about" className={classes.link}>
				About
			</Link>
		</Paper>
	);
}

export { MenuComponent }