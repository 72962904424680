import React, { Component } from 'react';
import { ProjectComponent } from '_components'
import { ProjectType } from '_types'

type Props = {
	projects: ProjectType[];
};

class ProjectsPage extends Component<Props, any> {
  	render() {
		return (
			<React.Fragment>
				<p>Projects:</p>
				{this.props.projects.map((project) => (
				<ProjectComponent key={project.name} project={project} />
				))}
			</React.Fragment>
		);
	}
}

export { ProjectsPage };
