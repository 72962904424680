import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import { environment } from '_environments'
import { FileType } from '_types';

export type FileComponentProps = {
  file: FileType;
};

export class FileComponent extends Component<FileComponentProps> {
	downloadFile(): void {
		window.open(environment.backend.api + '/download/?file=' + this.props.file.fullName, '_blank');
	}

	render() {
		return (
			<div>
				<IconButton aria-label="delete" onClick={() => this.downloadFile()}>
					<GetAppIcon fontSize="large" />
				</IconButton>
				{this.props.file.name}
			</div>
		);
	}
};
