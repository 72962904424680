import React from 'react';
import { environment } from '_environments'
import { apiStates, useApi } from '_hooks'
import { ProjectsPage } from '_scenes'
import { ProjectType } from '_types'

export const useProjects = () => {
	document.body.classList.add('projectsPage');
	const { state, error, data } = useApi(environment.backend.api + '/project/all');

	switch (state) {
		case apiStates.ERROR:
			return <p>ERROR: {error || 'General error'}</p>;
		case apiStates.SUCCESS:
			const projects: ProjectType[] = data;
			return (
				<ProjectsPage projects={projects} />
			);
		default:
			return <p>loading..</p>;
	}
};