import React, { Component } from 'react';

class AboutPage extends Component {
  constructor(props: any) {
		super(props);
		document.body.classList.add('aboutPage');
	}

  render() {
    return (
		<React.Fragment>
			<p>About:</p>
		</React.Fragment>
    );
  }
}

export { AboutPage };
