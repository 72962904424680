import React, { Component } from 'react';
import { DirectoryComponent } from '_components'
import { DirectoryType } from '_types'

type Props = {
  downloads: DirectoryType;
};

class DownloadsPage extends Component<Props, any> {
  	render() {
		return (
			<React.Fragment>
				<p>Downloads:</p>
				{this.props.downloads.directories.map((directory) => (
				<DirectoryComponent key={directory.name} directory={directory} />
				))}
			</React.Fragment>
		);
  	}
}

export { DownloadsPage };
