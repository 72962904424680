import React from 'react';
import { environment } from '_environments'
import { apiStates, useApi } from '_hooks'
import { DownloadsPage } from '_scenes'
import { DirectoryType } from '_types'

export const useDownloads = () => {
	document.body.classList.add('downloadsPage');
	const { state, error, data } = useApi(environment.backend.api + '/download/tree');

	switch (state) {
		case apiStates.ERROR:
			return <p>ERROR: {error || 'General error'}</p>;
		case apiStates.SUCCESS:
		const directory: DirectoryType = data;
			return (
				<DownloadsPage downloads={directory} />
			);
		default:
			return <p>loading..</p>;
	}
};